import React, { useEffect } from 'react';
import { useSearchParams, useNavigate, NavigateFunction } from 'react-router-dom';
import './Login.css';

import { UserApi } from '../../api/UserApi';
import { SessionUtils } from '../../api/SessionUtils';
import UserModel from "../../models/User";


type AppState = {
  username: string,
  email: string,
  email_verified: boolean,
  new_email: string,
  code_hint: string,
  tokens?: {
    id_token: string,
    access_token: string,
    refresh_token: string,
    expires_in: number,
    token_type: "Bearer",
  } | { error?: string }
}

type AppProps = {
  params: URLSearchParams
  navigate: NavigateFunction
}

function useUrlCode(): URLSearchParams {
  const [params,] = useSearchParams();

  useEffect(() => {
    return () => {
    };
  }, []);

  return params;
}

class Login
  extends React.Component<AppProps, AppState> {

  state: AppState = {
    username: SessionUtils.getUser()?.name || "",
    email: SessionUtils.getUser()?.email || "",
    email_verified: SessionUtils.getUser()?.email_verified || false,
    new_email: "",
    code_hint: ""
  }

  setEmail(email: string) {
    let tokens = JSON.parse(SessionUtils.tokens || "null");
    let headers = {};
    if (tokens && 'access_token' in tokens && 'id_token' in tokens) {
      headers = {
        "x-amzn-oidc-accesstoken": tokens.access_token,
        "x-amzn-oidc-data": tokens.id_token
      }
    }
    // console.log('header', headers);
    UserApi.setEmail(email, headers)
      .then(response => {
        // console.log('setEmail', response);
        if ("error" in response?.data) {
          console.log("something wrong:", response.data)
        } else {
          this.setState({
            "email": email,
            "email_verified": false,
            "new_email": ""
          });
          let user: UserModel = Object.assign(JSON.parse(SessionUtils.user || "{}"));
          user.email_verified = false;
          user.email = email;
          SessionUtils.user = JSON.stringify(user);
        }
      })
      .catch(e => { console.log('setEmail->error:', e) });
  }

  validateEmail(code: string) {
    let tokens = JSON.parse(SessionUtils.tokens || "null");
    let headers = {};
    if (tokens && 'access_token' in tokens && 'id_token' in tokens) {
      headers = {
        "x-amzn-oidc-accesstoken": tokens.access_token,
        "x-amzn-oidc-data": tokens.id_token
      }
    }
    UserApi.validateEmail(code, headers)
      .then(response => {
        // console.log('validateEmail', response);
        let payload = response?.data?.Payload;
        console.log(payload);
        payload = JSON.parse(payload || "null");
        payload = JSON.parse(payload || "null");
        // console.log("json", payload);

        let error = payload?.error;
        if (error) {
          if ("message" in error) {
            this.setState({ "code_hint": error.message });
          } else {
            this.setState({ "code_hint": "ERROR" });
          }
        } else {
          this.setState({
            "email_verified": true
          });
          let user: UserModel = Object.assign(JSON.parse(SessionUtils.user || "{}"));
          user.email_verified = true;
          SessionUtils.redirect = null;
          SessionUtils.user = JSON.stringify(user);
        }

      })
      .catch(e => { console.log('validateEmail->error:', e) });
  }

  getUserViaCode(code: string, cb: (user: (UserModel | { "error": string})) => void) {
    const currentRoute = window.location.origin + '/login';
    UserApi.getTokens(code, currentRoute)
      .then((response: any): any => {
        // console.log(`getUserTokens\n code: ${code}\n response.data: ${JSON.stringify(response.data||'').substring(0,42)}...`);
        SessionUtils.tokens = JSON.stringify(response.data);
        return response.data;
      })
      .then(cb)
      .catch(e => {
        console.log('token error', e);
        cb({ "error": e })
      })
  }

  getUser() {
    let username = "";
    UserApi.get(null)
      .then((response: any): UserModel => response.data)
      .then(user => {
        // console.log("USER", user);
        if (user) {
          username = user.name;
        }
        if (typeof username === "string" && username.length > 2) {
          // console.log('Got username:', username);
          let mergedUser = Object.assign(JSON.parse(SessionUtils.user || "{}"), user);
          SessionUtils.user = JSON.stringify(mergedUser);
          this.setState({
            username: user.name,
            email: user.email || "",
            email_verified: user.email_verified || false
          });
        } else {
          // console.log("error:", user);
        }
      })
      .catch(e => {
        console.log('UserApi catch', e);
      })
      .finally(() => {
        // console.log('Finally:', username);
        SessionUtils.login = null;
        if (username === "") {
          SessionUtils.redirect = window.location.pathname;
          window.location.assign('/login')
        } else {
          let pathname = SessionUtils.redirect;
          // console.log('pathname:', pathname);
          if (!this.doRedirect()) {
            window.location.assign(window.location.origin + window.location.pathname); // remove URL parameters (e.g. 'code')
          }
        }
      });

  }

  componentDidMount() {
    const CognitoCode = this.props.params.get('code');
    if ((typeof CognitoCode === "string") && (CognitoCode.length === 36) && !(SessionUtils.login === "1")) {
      SessionUtils.login = "1";
      this.getUserViaCode(CognitoCode, (user) => {
        SessionUtils.user = JSON.stringify(user);
        SessionUtils.login = null;
        let username = "";
        if ("error" in user) {
            console.log("error-in-login:", user);
        } else {
        if (user) {
          username = user.name;
        }
        if (typeof username === "string" && username.length > 2) {
          let mergedUser = Object.assign(JSON.parse(SessionUtils.user || "{}"), user);
          SessionUtils.user = JSON.stringify(mergedUser);
          this.setState({
            username: user.name,
            email: user.email || "",
            email_verified: user.email_verified || false
          });
          this.doRedirect();
        }
       }
      });
    }
    if (!(SessionUtils.login === "1") && (typeof this.state.username === "undefined" || this.state.username === "")) {
      const authUrl = "https://auth.coding-challenge.festo.com";
      const clientId = "5at8mustoaa4h9g0mf0n2d8o5"
      const currentRoute = window.location.origin + '/login';
      const scope = "openid+profile+aws.cognito.signin.user.admin";
      const login = `${authUrl}/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${currentRoute}&scope=${scope}`;
      window.location.assign(login);
      return
    }
    this.doRedirect();
  }

  doRedirect(): boolean {
    let user = SessionUtils.getUser();
    let pathname = SessionUtils.redirect;
    // console.log("redir", user, pathname);
    if (pathname && user && user.email && user.email !== "" && user.email_verified) {
      SessionUtils.redirect = null;
      window.location.assign(pathname);
      return true
    }
    return false
  }

  isEmailValid(email?: string): boolean {
    // Source: http://emailregex.com/
    const regEx = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return (typeof email === "string") ? regEx.test(email) : false;
  }

  submitData(event: React.SyntheticEvent) {
    event.preventDefault();
    this.setState({ "code_hint": "" });
    const target = event.target as typeof event.target & {
      email?: { value: string };
      code?: { value: string };
    };
    let new_email = target.email?.value;
    let code = target.code?.value;
    console.log('submit', new_email, code, new_email, new_email !== this.state.email, this.state.email_verified);
    if (code && code.length > 0) {
      this.validateEmail(code);
    }
    if (new_email) {
      this.setState({ 'new_email': new_email })
      if (this.isEmailValid(new_email)) {
        this.setEmail(new_email);
      }
    }
  }

  render() {
    if (SessionUtils.redirect &&
      (!(typeof this.state.username === "string" && this.state.username.length > 0)
        || ((this.state.email.length > 5) && (this.state.email_verified)))) {
      // console.log("RENDER", this.state);
      return <div className='login hud-box'>
        <div className='content'>
          <h1>Loading...</h1>
        </div>
      </div>
    }
    return (
      <div className='login hud-box'>
        <div className='content'>
          {(this.state.username.length > 42) ?
            <h1 title={this.state.username}>Hello {this.state.username.substring(0, 40)}...</h1>
            :
            <h1>Hello {this.state.username}</h1>
          }
          {(this.state.email === "") ?
            <p>Your e-mail is not set. Please add an email to be able to participate in the competition</p>
            :
            <p>
              Your current {this.state.email_verified === false && <span title="please enter verification code" className='bold'>unverified </span>}e-mail is: {this.state.email}
            </p>
          }

          <form id="email" onSubmit={(event) => { this.submitData(event) }} autoComplete="off">
            <span>e-mail:</span>
            {(this.state.new_email === "") &&
              <React.Fragment>
                <input
                  type="email"
                  name="email"
                  defaultValue={this.state.email} />&nbsp;
                <input
                  type="submit"
                  value="Submit e-mail and request code" />
              </React.Fragment>
            }
            {(this.state.new_email !== "") &&
              <React.Fragment>
                <input
                  type="email"
                  className={this.isEmailValid(this.state.new_email) ? "valid" : "invalid"}
                  title={this.isEmailValid(this.state.new_email) ? "" : "invalid email address"}
                  name="email"
                  defaultValue={this.state.new_email} />&nbsp;
                <input
                  type="submit"
                  className={this.isEmailValid(this.state.new_email) ? "valid" : "invalid"}
                  value="Submit e-mail and request code" />
              </React.Fragment>
            }
          </form>
          {this.state.email_verified === false &&
            <form id="email_code" onSubmit={(event) => { this.submitData(event) }} autoComplete="off">
              <span>code:</span>
              <input
                type="text"
                className={this.state.code_hint === "" ? "" : this.state.code_hint === "success" ? "valid" : "invalid"}
                title={this.state.code_hint}
                name="code" />&nbsp;
              <input
                type="submit" value="Submit verification code"
                className={this.state.code_hint === "" ? "" : this.state.code_hint === "success" ? "valid" : "invalid"} />
            </form>
          }

          <button onClick={() => window.location.assign("/")}>Back to main page</button>
        </div>
      </div>
    );
  }
}

const withHooks = (Component: any) => {
  return (props: any) => {
    const params = useUrlCode();
    const navigate = useNavigate()

    return <Component params={params} navigate={navigate} {...props} />;
  };
};

export default withHooks(Login);