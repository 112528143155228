import "./Court.css";

import { ApiUtils } from "../../api/ApiUtils";
import EpisodeDto from "../../models/Episode";

export default function Court(parameters: { tutorialSolved: boolean, episodeList: EpisodeDto[] }) {
    if (parameters.tutorialSolved) {
        return (
            <div id="court" className={`hud-box`}>
                <div className={`content court`}>
                    <h1>Court</h1>
                    <div className="text">Here you can see your progress in the three main episodes. Identify pirates to unlock their profile picture. Solve puzzles to earn evidence points.</div>
                    <div className="court-list">
                        {parameters.episodeList.map(episode => episode.villain && episode.villain.name ? <EpisodeVillain key={`villain_${episode.id.id}`} episode={episode} /> : null)}
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div id="court" className={`hud-box`}>
                <div className={`content court-locked`}>
                    <div className="lock">
                        <span className="material-symbols-rounded">lock</span>
                        <p>Solve the intro to unlock this.</p>
                    </div>
                </div>
            </div>
        )
    }
}

function EpisodeVillain(parameters: { episode: EpisodeDto }) {
    var episode = parameters.episode;
    if (episode.hasVillain() && episode.villain.image) {
        return (
            <div className="villain-showcase">
                <img height={`300px`} src={`${ApiUtils.url()}/material/2022/${episode.villain.image}`} />
                {!episode.hasRestrictions() ? <h3>{episode.villain.name}</h3> : <h3>Unknown</h3>}
                <EvidencePointStars episode={episode} />
            </div >
        )
    }
    return (null);
}


function EvidencePointStars(parameters: { episode: EpisodeDto }) {
    var episode = parameters.episode;
    return (
        <div className="evidence-points">
            <ul>
                {episode.stages.map((stage) => {
                    if (stage.isSolved) {
                        return (<li key={`evidence_${stage.puzzleId.id}_checked`}><span className="material-symbols-rounded">star</span></li>)
                    } else {
                        return (<li key={`evidence_${stage.puzzleId.id}_notdone`}><span className="material-symbols-rounded not-completed">star</span></li>)
                    }
                })
                }
            </ul>
        </div>
    )
}