import './BlogView.css';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { format } from 'date-fns';

import { BlogEntry } from '../models/BlogEntry';
import { BlogApi } from '../api/BlogApi';

type PuzzleViewPathParameters = {
    title: string
}

export default function BlogVIew() {
    const { title } = useParams<PuzzleViewPathParameters>();

    const [blogEntry, setBlogEntry] = useState<BlogEntry | null>(null);

    useEffect(() => {
        if (title && title !== "") {
            BlogApi.getByTitle(title)
                .then(blogEntry => setBlogEntry(blogEntry));
        }
    }, [title]);

    if (!title || !blogEntry) {
    } else if (blogEntry && blogEntry.content) {
        return (
            <div className="blog-page">
                <div id="content" className="hud-box">
                    <div className="text" dangerouslySetInnerHTML={{ __html: blogEntry.content }} />
                </div>
            </div>
        );
    }
    return (<div></div>);
}