import "./EpisodeMenu.css";

import EpisodeDto, { RestrictionType, StageDto } from "../../models/Episode";
import PuzzleMenuEntry from "./PuzzleMenuEntry";
import { format } from "date-fns";

export default function EpisodeOverview(parameters: { episode: EpisodeDto }) {
    let episode = parameters.episode;
    let episodeTitle = parameters.episode.episodeTitle.split(" - ");
    let episodeNumber = episodeTitle[0];

    if (episode && episode.stages) {
        if (!episode.hasRestrictions()) {
            return (
                <nav key={`episode_nav_${episode.id.id}`} role="navigation" className="episode-menu">
                    <div>
                        <h2>{episodeNumber}</h2>
                        {episode.hasVillain() ? <h3>{episode.villain.name}</h3> : <h3>&nbsp;</h3>}
                        <ul className="list">
                            <li key={`episode_${episode.id.id}_${episode.puzzleId.id}`}><PuzzleMenuEntry stage={episode} wasSolved={episode.isSolved} /></li>
                            {episode.stages
                                .sort((s1: StageDto, s2: StageDto) => s1.puzzleId.id - s2.puzzleId.id)
                                .map(
                                    (stage) =>
                                        <li key={`episode_${episode.id.id}_${stage.puzzleId.id}`}>
                                            <PuzzleMenuEntry stage={stage} wasSolved={stage.isSolved} />
                                        </li>
                                )
                            }
                        </ul>
                    </div>
                </nav>
            );
        } else {
            return (
                <nav key={`episode_nav_${episode.id.id}`} role="navigation" className={`episode-menu locked`}>
                    <div>
                        <h2>{episodeNumber}</h2>
                        <h3>&nbsp;</h3>
                        <div className="episode-locked">
                            <RestrictionMessage episode={episode} />
                        </div>
                    </div>
                </nav>
            );
        }
    } else {
        return (<div>Episode not found.</div>);
    }
}


function RestrictionMessage(parameters: { episode: EpisodeDto }) {
    var episode = parameters.episode;
    var ret = [];
    if (episode.restrictions) {
        if (episode.hasRestrictions(RestrictionType.Dependencies)) {
            if (episode.restrictions.depends_on_message) {
                ret.push(<div className="enter puzzle-restricted" key={`episode_restriction_${episode.id.id}`}>
                    <span className="material-symbols-rounded">lock</span>
                    <span> {episode.restrictions.depends_on_message}</span>
                    </div>)

            } else {
                ret.push(<div className="enter puzzle-restricted" key={`episode_restriction_depend_${episode.id.id}`}>
                    <span className="material-symbols-rounded">lock</span>
                    <span> Solve {episode.restrictions.depends_on[0]} to unlock.</span>
                    </div>)
            }

        }

        if (episode.hasRestrictions(RestrictionType.Date)) {
            if (episode.restrictions.date) {
                var restrictionDate = new Date(episode.restrictions.date);
                var restrictedDateAsString = format(restrictionDate, "dd.MM.yyyy");
                ret.push(<div className="enter date-restricted" key={`episode_restriction_date_${episode.id.id}`}>
                    <span className="material-symbols-rounded">schedule</span>
                    <span> Available on {restrictedDateAsString}</span>
                    </div>)
            } else {
                ret.push(<div className="enter date-restricted" key={`episode_restriction_date_na_${episode.id.id}`}>
                    <span className="material-symbols-rounded">schedule</span>
                    <span> Available on UNKNOWN DATE.</span>
                    </div>)
            }
        }
    }
    return <>{ret}</>
}
