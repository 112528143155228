import { AxiosResponse } from "axios";

import { ApiUtils } from "./ApiUtils";

import Puzzle, { SolveResultDto } from "../models/Puzzle";
import EpisodeDto from "../models/Episode";
import { EpisodeApi } from "./EpisodeApi";

class PuzzleApiImpl {

    public static INSTANCE: PuzzleApiImpl = new PuzzleApiImpl();

    public get(): Promise<AxiosResponse<any, any>> {
        return ApiUtils.restApi().get('puzzle/');
    }

    public getPuzzleById(puzzleId: {id: number, year: number}): Promise<Puzzle> {
        return ApiUtils.restApi().get(`puzzle/${puzzleId.year}/${puzzleId.id}`)
            .then(jsonResult => {
                if (jsonResult.data) {
                    return jsonResult.data;
                }
                return undefined;
            });
    }

    public getEpisodeByPuzzleId(puzzleId: {id: number, year: number}): Promise<EpisodeDto> {
        return ApiUtils.restApi().get(`puzzle/${puzzleId.year}/${puzzleId.id}/episode`)
            .then(jsonResult => {
                return EpisodeApi.mapToDto(jsonResult.data);
            });
    }

    public solve(puzzleId: {id: number, year: number}, password: string): Promise<SolveResultDto> {
        return ApiUtils.restApi().post(
            `puzzle/${puzzleId.year}/${puzzleId.id}/solve`,
            { password: password }
        ).then(jsonResult => {
            if (jsonResult.data) {
                return jsonResult.data;
            }
            return false;
        });
    }

}

export const PuzzleApi = PuzzleApiImpl.INSTANCE;