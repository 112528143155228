import "./DataLibrary.css";

import { ApiUtils } from "../../api/ApiUtils";

import Puzzle from "../../models/Puzzle";

export default function DataLibrary(parameters: { puzzle: Puzzle }) {
    var puzzle = parameters.puzzle;
    if (puzzle && puzzle.content.flavor_data && puzzle.content.flavor_data.data_library) {
        let dataLibraryItems = puzzle.content.flavor_data.data_library as string[];
        return (
            <div className="hud-box">
                <aside className="data-library">
                    <h1>Data Library</h1>
                    <ul>
                        {dataLibraryItems.map((value, index) =>
                            <DataLibraryItem puzzle={puzzle} item={value} index={index} key={`data-library_${parameters.puzzle.id}_${index}`} />
                        )}
                    </ul>
                </aside>
            </div>
        )
    }
    return (null);
}

function DataLibraryItem(parameters: { puzzle: Puzzle, item: any, index: number }) {
    if (parameters.item.toString() === "SEPARATOR") {
        return (
            <li>
                <hr />
            </li>
        )
    }
    return (
        <li>
            <span className="material-symbols-rounded">draft</span>&nbsp;
            <span className="description"><a href={`${ApiUtils.url()}/material/2022/${parameters.item}`} download>{parameters.item}</a></span>
        </li>
    )
}