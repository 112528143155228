import "./Header.css";

import User from "../User/User";
import { NavLink } from "react-router-dom";

export default function Header() {
    return (
        <header role="banner">
            <h1><NavLink to="/">Festo Coding Challenge 2022</NavLink></h1>
            <User />
        </header>
    )
}