
import { ApiUtils } from "./ApiUtils";

import User from "../models/User";

class UserApiImpl {

    public static INSTANCE: UserApiImpl = new UserApiImpl();

    public get(headers: any): Promise<User> {
        return ApiUtils.restApi().get('user/', { headers: headers });
    }

    public getSessionData(): Promise<User> {
        return ApiUtils.restApi().get(`user/current`).then(result => result.data);
    }

    public setProperty(propertyName: string, valueAsString: string): Promise<User> {
        return ApiUtils.restApi().post(`user/update`, { property: propertyName, value: valueAsString })
            .then(jsonResponse => {
                if (jsonResponse.data) {
                    return Object.assign(new User(), jsonResponse.data)
                }
                return undefined;
            });
    }

    public getTokens(code: string, url: string): Promise<any> {
        return ApiUtils.restApi().post('user/', {
            code: code,
            url: url
        })
    }

    public setEmail(email: string, headers: any): Promise<any> {
        return ApiUtils.restApi().post('user/email', { email: email }, { headers: headers })
    }

    public validateEmail(code: string, headers: any): Promise<any> {
        return ApiUtils.restApi().post('user/email', { code: code }, { headers: headers })
    }
}

export const UserApi = UserApiImpl.INSTANCE;