import "./CongratulationBanner.css";

import { NavLink } from "react-router-dom";
import { SessionUtils } from "../../api/SessionUtils";

export default function CongratulationBanner() {
    const user = SessionUtils.getUser();

    var userHasEmail = (typeof user?.email === "string") && (user?.email_verified === true);
    var hasSolvedEverything = user?.hasSolvedPuzzle(43);
    var hasSolvedMainStory = user?.hasSolvedPuzzles([10, 12, 12, 13, 20, 21, 22, 23, 30, 31, 32, 33]);

    return (
        <div id="gratulation-banner" className={hasSolvedEverything ? "everything" : ""}>
            <div className="credits"><NavLink className="button" to={`/credits`}>Credits</NavLink></div>
            <h2>Challenge completed!</h2>
            <div>
                <ul className="checklist">
                    <li className="done">Main storyline finished (Episodes 1, 2, 3)</li>
                    <li className={userHasEmail ? "done" : "not-done"}>E-Mail added and validated</li>
                    <li>&nbsp;</li>
                    {userHasEmail ?
                        <li className="summary done">You will be part of the prize draw on July 31st 2022.</li>
                        :
                        <li className="summary not-done">If you want to be part of the prize draw, please enter and validate your e-mail address</li>
                    }
                </ul>
                <br></br>
                <span>
                {hasSolvedEverything ? "You have solved the final episode. This concludes your adventure." : "There is more (optional) content to discover. Can you complete the final episode?"}
                </span>
            </div>
        </div>
    );
}