import debugEnabled from "../utils/logging";

import User from "../models/User";
import { UserApi } from "./UserApi";

const handler: ProxyHandler<SessionUtilsImpl> = {
    get(target, property): any {
        const value = sessionStorage.getItem(String(property));
        const propertyType = typeof target[property as keyof typeof target];
        /*
        if (debugEnabled()) {
            console.log(`getting Session Item (${propertyType}): ${String(property)}="${value}"`)
        }
        */
        if (propertyType === "function") {
            return target[property as keyof typeof target];
        }
        return value;
    },
    set(target, property, value: string | null): boolean {
        if (debugEnabled()) {
            if (value === null) {
                console.log(`removing Session Item: ${String(property)}`)
            } else {
                console.log(`setting Session Item: ${String(property)}="${value}"`)
            }
        }
        if (value === null) {
            sessionStorage.removeItem(String(property));
        } else {
            sessionStorage.setItem(String(property), value);
        }
        return true;
    }
}

class SessionUtilsImpl {

    public static INSTANCE: SessionUtilsImpl = new Proxy(new SessionUtilsImpl(), handler);

    private constructor() {
        UserApi.getSessionData().then(user => {
            sessionStorage.setItem(String("user"), JSON.stringify(user));
        });
        if (debugEnabled()) {
            console.log(`SessionUtils`);
        }
    }

    public clearAll(): void {
        sessionStorage.clear();
    }

    public getUser(): User | null {
        try {
            return Object.assign(new User(), JSON.parse(sessionStorage.getItem('user') || "null"));
        } catch (error) {
            console.log("Error parsing JSON (SessionUtils)");
            return null;
        }
    }

    public setUser(user: User) {
        sessionStorage.setItem('user', JSON.stringify(user));
    }

    /* List of property names to store in browser session
     * If "null" is assigned, key (and value) will be removed from session storage
     */

    public user: string | null = null;

    public redirect: string | null = null;

    public tokens: string | null = null;

    public login: string | null = null;

}

export const SessionUtils = SessionUtilsImpl.INSTANCE;