import "./AccessControlPanel.css";

import React from "react";

import EventBus, { Events } from "../../utils/EventBus";

import { PuzzleApi } from "../../api/PuzzleApi";
import { SessionUtils } from "../../api/SessionUtils";

import EpisodeDto from "../../models/Episode";
import Puzzle from "../../models/Puzzle";
import User from "../../models/User";
import { AxiosError } from "axios";
import Snackbar from "../Site/Snackbar";

const MS_IN_S = 1000;
const SNACKBAR_DISPLAY_TIME_IN_S = 15 * MS_IN_S;
const THROTTLE_TIME_IN_S = 10 * MS_IN_S;

type AccessControlPanelProperties = {
    puzzle: Puzzle,
    episode: EpisodeDto | null,
    solved: boolean
}

type AccessControlPanelState = {
    resultMessage: string | null,
    showUnlockNotification: boolean,
    unlockNotification: string | null,
    isEpisode: boolean,
    throttleActive: boolean,
    throttleTimer: ReturnType<typeof setTimeout> | null
}


class AccessControlPanel
    extends React.Component<AccessControlPanelProperties, AccessControlPanelState> {

    state: AccessControlPanelState = {
        resultMessage: "",
        showUnlockNotification: false,
        unlockNotification: null,
        isEpisode: false,
        throttleActive: false,
        throttleTimer: null
    }

    passwordPanel: React.RefObject<HTMLFormElement>;
    accessNotificationBanner: React.RefObject<HTMLDivElement>;

    constructor(properties: AccessControlPanelProperties) {
        super(properties);

        this.passwordPanel = React.createRef();
        this.accessNotificationBanner = React.createRef();

        this.updatePanelVisibility = this.updatePanelVisibility.bind(this);
        this.solvePuzzle = this.solvePuzzle.bind(this);
        this.removeFailedStyle = this.removeFailedStyle.bind(this);
        this.removeBlockedStyle = this.removeBlockedStyle.bind(this);
    }

    componentDidMount() {
        this.updateState();
        this.updatePanelVisibility();
    }

    componentDidUpdate() {
        this.updateState();
        this.updatePanelVisibility();
    }

    render() {
        if (!this.props.puzzle?.content?.needsPassword) {
            return (null);
        }
        return (
            <div key={this.props.puzzle.id.id} className="access-control">
                <form id="password-panel" onSubmit={(event) => { this.solvePuzzle(event) }} autoComplete="off" ref={this.passwordPanel}>
                    <input type="hidden" name="puzzleId" value={`${this.props.puzzle.id.id}`} />
                    <ul>
                        <li key={`terminal_${this.props.puzzle.id.id}`} className="terminal">
                            <input id="terminal-input" type="text" name="password" onInput={this.removeFailedStyle} />
                        </li>
                        <li key={`button_${this.props.puzzle.id.id}`} className="button">
                            <input id="solve-button" type="submit" value="Submit" />
                        </li>
                    </ul>
                    <div className={`throttle-notification hidden`}>You tried too often to enter a wrong password. Please wait 10 seconds.</div>
                </form>
                <div id="access-granted" className="hidden" ref={this.accessNotificationBanner}>
                    <AccessGrantedBannerText isEpisode={this.state.isEpisode} message={this.state.resultMessage} />
                </div>
                <Snackbar open={this.state.showUnlockNotification} message={this.state.unlockNotification} onClose={() => this.setState({ showUnlockNotification: false })} />
            </div>
        );
    }

    private solvePuzzle(event: React.SyntheticEvent) {
        event.preventDefault();
        const target = event.target as typeof event.target & {
            puzzleId: { value: number };
            password: { value: string };
        };
        const puzzleId = target.puzzleId.value;
        const password = target.password.value;

        PuzzleApi.solve({id: puzzleId, year: 2022}, password).then(result => {
            if (result.solved) {
                this.startSuccessAnimation(target);
                this.updateUserSession(puzzleId);
                if (result.message) {
                    this.setState({ resultMessage: result.message });
                }
                if (result.unlock_notification) {
                    this.setState({ unlockNotification: result.unlock_notification, showUnlockNotification: true });
                    setTimeout(() => {
                        this.setState({ showUnlockNotification: false, unlockNotification: null });
                    }, SNACKBAR_DISPLAY_TIME_IN_S * MS_IN_S);
                }
                EventBus.emit(Events.PuzzleSolved, puzzleId);
            } else {
                if (target instanceof Element) {
                    this.setSuccessOrFailedStyle("failed", target);
                }
            }
        }).catch((error: AxiosError) => {
            if (error?.response?.status === 503) {
                if (this.state.throttleTimer) {
                    clearTimeout(this.state.throttleTimer);
                }
                var timer = setTimeout(() => {
                    this.setState({ throttleActive: false });
                    this.removeBlockedStyle();
                    this.setState({ throttleTimer: null });
                }, THROTTLE_TIME_IN_S);
                this.setSuccessOrFailedStyle("blocked", this.passwordPanel.current as Element);

                this.passwordPanel.current?.querySelector("#terminal-input")?.setAttribute("disabled", "disabled");
                this.passwordPanel.current?.querySelector("#solve-button")?.setAttribute("disabled", "disabled");
                this.passwordPanel.current?.querySelector("#terminal-input")?.classList.add("blocked");
                this.passwordPanel.current?.querySelector("#solve-button")?.classList.add("blocked");
                this.passwordPanel.current?.querySelector(".throttle-notification")?.classList.remove("hidden");

                this.setState({ throttleActive: true, throttleTimer: timer });
            }
        });
    }

    private removeFailedStyle(event: React.SyntheticEvent) {
        this.passwordPanel.current?.querySelector("#terminal-input")?.classList.remove("failed");
        this.passwordPanel.current?.querySelector("#solve-button")?.classList.remove("failed");
    }

    private removeBlockedStyle() {
        this.passwordPanel.current?.querySelector("#terminal-input")?.classList.remove("blocked");
        this.passwordPanel.current?.querySelector("#solve-button")?.classList.remove("blocked");
        this.passwordPanel.current?.querySelector("#terminal-input")?.removeAttribute("disabled");
        this.passwordPanel.current?.querySelector("#solve-button")?.removeAttribute("disabled");
        this.passwordPanel.current?.querySelector(".throttle-notification")?.classList.add("hidden");
    }

    private updatePanelVisibility() {
        const user = SessionUtils.getUser();
        if (this.props.solved || user?.hasSolvedPuzzle(this.props.puzzle.id.id)) {
            this.passwordPanel.current?.classList.add("hidden");
            this.accessNotificationBanner.current?.classList.remove("hidden");
        } else {
            this.passwordPanel.current?.classList.remove("hidden");
            this.accessNotificationBanner.current?.classList.add("hidden");
        }
    }

    private updateState() {
        var newResultMessage = this.state.resultMessage;
        if (this.props.puzzle.content?.result?.text) {
            newResultMessage = this.props.puzzle.content.result.text;
        } else {
            newResultMessage = "";
        }
        var newIsEpisode = this.props.episode !== null && (this.props.episode.puzzleId.id == this.props.puzzle.id.id);
        if (this.state.resultMessage != newResultMessage || this.state.isEpisode != newIsEpisode) {
            this.setState({ resultMessage: newResultMessage, isEpisode: newIsEpisode });
        }
    }

    private startSuccessAnimation(target: EventTarget & { puzzleId: { value: number; }; password: { value: string; }; }) {
        if (target instanceof Element) {
            this.setSuccessOrFailedStyle("success", target);
            this.passwordPanel.current?.classList.add("hidden");

            const accessGrantedBanner = target.parentElement?.querySelector("#access-granted");
            accessGrantedBanner?.classList.remove("hidden");
        }
    }

    private updateUserSession(puzzleId: number) {
        var user = SessionUtils.getUser();
        if (!user) {
            user = new User();
        }
        user.solvedPuzzles[puzzleId] = true;
        SessionUtils.user = JSON.stringify(user);
    }

    private setSuccessOrFailedStyle(style: string, target: Element) {
        const solveButton = target.querySelector("#solve-button");
        const terminalInput = target.querySelector("#terminal-input");
        const throttleNotification = target.querySelector(".throttle-notification");

        solveButton?.classList.remove("success");
        solveButton?.classList.remove("failed");
        solveButton?.classList.remove("blocked");

        terminalInput?.classList.remove("success");
        terminalInput?.classList.remove("failed");
        terminalInput?.classList.remove("blocked");

        throttleNotification?.classList.add("hidden");

        solveButton?.classList.add(style);
        terminalInput?.classList.add(style);
    }

}

function AccessGrantedBannerText(parameters: { isEpisode: boolean, message: string | null }) {
    if (parameters.message) {
        return (
            <span id="congratulation-text">
                <span className="material-symbols-rounded">done</span> {parameters.message}
            </span>
        )
    }
    if (parameters.isEpisode) {
        return (
            <span id="congratulation-text">
                Congratulations! Episode solved.
            </span>
        )
    }
    return (
        <span id="congratulation-text">
            <span className="material-symbols-rounded">done</span> Puzzle solved! You earn one Evidence Point <span className="material-symbols-rounded">star</span>
        </span>
    )
}

export default AccessControlPanel;