import React from 'react';
import './User.css';

import { SessionUtils } from '../../api/SessionUtils';

type AppState = {
  username?: string,
  email?: string
}

class User
  extends React.Component<{}, AppState> {

  state: AppState = {
    username: "",
    email: ""
  }

  handleEmailClick(target: React.MouseEvent) {
    window.location.assign("/login");
  }

  handleLoginClick(target: React.MouseEvent) {
    if (typeof this.state.username === "undefined" || this.state.username === "") {
      target.currentTarget.innerHTML = "Loading...";
      SessionUtils.redirect = window.location.pathname;
      window.location.assign("/login");
    }
  }

  handleLogoutClick(target: React.MouseEvent) {
    target.currentTarget.innerHTML = "Logging Out...";
    SessionUtils.clearAll();
    this.setState({ username: "", email: "" });
    let logout_uri = `${window.location.origin}/api/user/logout`;
    if (window.location.hostname === 'localhost') {
      logout_uri = "http://localhost:3000/api/user/logout"
    }
    window.location.assign(`https://auth.coding-challenge.festo.com/logout?logout_uri=${logout_uri}&client_id=5at8mustoaa4h9g0mf0n2d8o5`);
  }

  componentDidMount() {
    if (SessionUtils.getUser()?.name !== this.state.username) {
      this.setState({ 'username': SessionUtils.getUser()?.name, 'email': SessionUtils.getUser()?.email })
    }
  }

  render() {
    if (!SessionUtils.login)
      return (
        <div id="user" >
          <div>
            {(typeof this.state.username === "undefined" || this.state.username === "") ?
              <span onClick={this.handleLoginClick.bind(this)} className="login">Login</span>
              :
              <React.Fragment>
                <span className="material-symbols-rounded">person</span>
                <div className="user">
                  {(this.state.username.length > 42) ?
                    <span title={this.state.username}>{this.state.username.substring(0, 40)}...</span>
                    :
                    <span>{this.state.username}</span>
                  }
                  <div>
                    <div id="menu">
                      <span onClick={this.handleEmailClick.bind(this)}>E-mail</span>
                      <span onClick={this.handleLogoutClick.bind(this)}>Logout</span>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
          </div>
        </div>
      );
  }
}

export default User;

