
export class StageDto {

    puzzleId: { id: number; year: number } = { id: 0, year: 2022 };
    title: string = "";

    isSolved: boolean = false;
    needsPassword: boolean = true;
    restrictions: RestrictionDto | null = null;

    public hasRestrictions(restrictionType: RestrictionType | null = null): boolean {
        if (restrictionType === null) {
            return this.restrictions !== null && this.restrictions.type > 0;
        }
        return this.restrictions !== null && (this.restrictions.type & restrictionType) === restrictionType;
    }

}

export default class EpisodeDto
    extends StageDto {

    id: { id: number; year: number } = { id: 0, year: 2022 };
    episodeTitle: string = "";

    villain: VillainDto = new VillainDto();

    stages: StageDto[] = [];

    public hasVillain(): boolean {
        return this.villain && this.villain.name !== "";
    }

}

export class RestrictionDto {

    type: RestrictionType = RestrictionType.None;
    depends_on: number[] = [];
    depends_on_message: string = "";
    date: Date | null = null;

}

export class VillainDto {

    name: string = "";
    image: string = "00_unknown_person.png";

}

export enum RestrictionType {
    None = 0,
    Date = 1,
    Dependencies = 2
}