import './WelcomeView.css';
import React, { useEffect, useState } from 'react';

import { EpisodeApi } from '../api/EpisodeApi';
import { SessionUtils } from '../api/SessionUtils';

import EpisodeDto from '../models/Episode';

import WelcomeText from '../components/Welcome/WelcomeText';
import EpisodeOverview from '../components/Puzzle/EpisodeOverview';
import Court from '../components/Welcome/Court';
import CongratulationBanner from '../components/Welcome/CongratulationBanner';

export default function WelcomeView() {

  const [episodeList, setEpisodeList] = useState<EpisodeDto[]>([]);
  const [puzzleCount, setPuzzleCount] = useState<number>(0);
  const [userSolvedCount, setUserSolvedCount] = useState<number>(0);
  const [tutorialSolved, setTutorialSolved] = useState<boolean>(false);
  const [mainStorySolved, setMainStorySolved] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    EpisodeApi.get()
      .then(episodes => {
        setEpisodeList(episodes);
        var puzzleCount = 0;
        episodes.forEach(episode => {
          if (episode.needsPassword) {
            puzzleCount++;
          }
          episode.stages.forEach(stage => stage.needsPassword ? puzzleCount++ : false);
        })
        setPuzzleCount(puzzleCount);

      })
      .finally(() => setLoaded(true));
  }, []);

  useEffect(() => {
    var solvedPuzzleCount = 0;
    const user = SessionUtils.getUser();
    console.log("user", user);
    if (user && Array.isArray(user.puzzle_progress)) {
      let puzzleProgressOfSelectedYear = user.puzzle_progress.filter(y => y.year === 2022)[0];
      if (puzzleProgressOfSelectedYear && Array.isArray(puzzleProgressOfSelectedYear.solvedPuzzles)) {
        puzzleProgressOfSelectedYear.solvedPuzzles.forEach((value, index) => value ? solvedPuzzleCount++ : false);
      }
      if (user.hasSolvedPuzzle(0)) {
        setTutorialSolved(true);
      }
      setUserSolvedCount(solvedPuzzleCount);
      setMainStorySolved(user.hasSolvedPuzzle(10) && user.hasSolvedPuzzle(20) && user.hasSolvedPuzzle(30));
    }
  }, [episodeList]);

  if (loaded) {
    return (
      <React.Fragment>
        <WelcomeText />
        <div id="episode-overview" className="hud-box">
          <div className="content">
            {mainStorySolved ? <CongratulationBanner /> : null}
            <div className="episode-overview">
              {episodeList.map(episode =>
                <EpisodeOverview key={`episode_overview_${episode.id.id}`} episode={episode} />
              )}
            </div>
            <div className="solved-count">Puzzles solved: {userSolvedCount} / {puzzleCount}</div>
          </div>
        </div>
        <Court episodeList={episodeList} tutorialSolved={tutorialSolved} />
      </React.Fragment>
    );
  } else {
    return (null);
  }
}
