import './CreditsView.css';

import { NavLink } from 'react-router-dom';

export default function CreditsView() {
    return (
        <div id="content" className="hud-box">
            <div className="content">
                <div className="credits">
                    <div className="image">
                        <img src="codingchallenge_team.png" />
                    </div>
                    <div className="team">
                        <h1>Our Team</h1>
                        From left to right: <br />
                        <ul>
                            <li>Verena Böck</li>
                            <li>Michael Sinsbeck</li>
                            <li>Fabian Wolf</li>
                            <li>Jan Seyler</li>
                            <li>Sina-Madeleine Haug</li>
                            <li>Bastian Ohmer</li>
                        </ul>
                        <br />
                        <NavLink className="button" to={"/"}>Back to main page</NavLink >
                    </div>
                </div>
            </div>
        </div>
    )
}