import debugEnabled from "../utils/logging";

import axios, { AxiosInstance } from "axios";

class ApiUtilsImpl {

    public static INSTANCE: ApiUtilsImpl = new ApiUtilsImpl();

    private baseUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:3000";

    private constructor() {
        if (debugEnabled()) {
            console.log(`ApiUtils: REACT_APP_URL=${process.env.REACT_APP_API_URL}`);
            console.log(`ApiUtils: baseUrl=${this.baseUrl}`);
        }
        if (this.baseUrl === "/") {
            this.baseUrl = "";
        }
    }

    public url(): string {
        return `${this.baseUrl}/api`;
    }

    public restApi(): AxiosInstance {
        let axiosInstance: AxiosInstance = axios.create({
            baseURL: `${this.url()}`,
            headers: {
                "Content-type": "application/json"
            }
        });
        axiosInstance.defaults.withCredentials = true;
        return axiosInstance;
    }

}

export const ApiUtils = ApiUtilsImpl.INSTANCE;