export class YearSpecificPuzzleProgressDto
{
    year: number = 2022;

    activePuzzle: number = 0;
    solvedPuzzles: boolean[] = [];
}
export default class User {

    name: string = "";
    email?: string;
    email_verified = false;

    activePuzzle: number = 0;
    get solvedPuzzles(): boolean[] {
        let puzzleProgressOfSelectedYear = this.getPuzzleProgressOfYear(2022);
        if (puzzleProgressOfSelectedYear && Array.isArray(puzzleProgressOfSelectedYear.solvedPuzzles)) {
            return puzzleProgressOfSelectedYear.solvedPuzzles
        }
        return []
    }
    puzzle_progress: YearSpecificPuzzleProgressDto[] = [{ year: 2022, activePuzzle: 0, solvedPuzzles: []}];

    private getPuzzleProgressOfYear(year: number) {
        return this.puzzle_progress.filter(y => y.year === year)[0];
    }

    welcomeDone: boolean = false;

    public hasSolvedPuzzle(id: number) {
        let puzzleProgressOfSelectedYear = this.puzzle_progress.filter(y => y.year === 2022)[0];
        if (puzzleProgressOfSelectedYear && Array.isArray(puzzleProgressOfSelectedYear.solvedPuzzles)) {
            return puzzleProgressOfSelectedYear.solvedPuzzles.length > id && puzzleProgressOfSelectedYear.solvedPuzzles[id] === true;
        }
        return false;
    }

    public hasSolvedPuzzles(ids: number[]) {
        var hasSolvedAll = true;
        ids.forEach(id => hasSolvedAll = hasSolvedAll && this.hasSolvedPuzzle(id));
        return hasSolvedAll;
    }

}