import "./Footer.css";

export default function Footer() {
    return (
        <footer role="footer" className="site-footer">
            <ul>
                <li><a href="https://www.festo.com/group/en/cms/10203.htm" target="_blank">Imprint - Legal Information</a></li>
                <li><a href="https://www.festo.com/group/en/cms/10195.htm" target="_blank">Data protection</a></li>
            </ul>
        </footer>
    )
}