import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { SessionUtils } from "../../api/SessionUtils";
import EpisodeDto, { RestrictionType, StageDto } from "../../models/Episode";
import EventBus, { Events } from "../../utils/EventBus";

export default function PuzzleMenuEntry(properties: { stage: StageDto, wasSolved: boolean }) {
    const [solved, setSolved] = useState(false);
    const [locked, setLocked] = useState(false);

    const stage = properties.stage;

    useEffect(() => {
        var currentUser = SessionUtils.getUser();
        if (stage.hasRestrictions()) {
            if (stage.restrictions && currentUser && currentUser.hasSolvedPuzzles(stage.restrictions.depends_on)) {
                setLocked(false);
            } else {
                setLocked(true);
            }
        }


        function handlePuzzleSolvedEvent(puzzleIdAsString: string) {
            var puzzleId = parseInt(puzzleIdAsString);
            if (puzzleId === stage.puzzleId.id) {
                setSolved(true);
            }
            var eventUser = SessionUtils.getUser();
            if (stage.restrictions
                && stage.restrictions.depends_on.indexOf(puzzleId) >= 0
                && eventUser
                && eventUser.hasSolvedPuzzles(stage.restrictions.depends_on)) {
                setLocked(false);
            }
        }
        EventBus.on(Events.PuzzleSolved, handlePuzzleSolvedEvent);

        return function cleanup() {
            EventBus.off(Events.PuzzleSolved, handlePuzzleSolvedEvent);
        }
    }, [stage, stage.puzzleId.id]);

    var isSolved = solved || properties.wasSolved;
    if (locked) {
        return (
            <span className="nav-button locked">
                <span className="material-symbols-rounded">lock</span>
            </span>
        )
    } else {
        return (
            <NavLink to={`/puzzle/${stage.puzzleId.id}`} className={({ isActive }) => isActive ? "nav-button active" : "nav-button"}>
                <span className="title">{stage.title}</span>
                {stage.needsPassword ? <EntryIcon stage={stage} isSolved={isSolved} /> : null}
            </NavLink>)
    }
}

function EntryIcon(parameters: { stage: StageDto, isSolved: boolean }): React.ReactElement {
    var isEpisode: boolean = parameters.stage instanceof EpisodeDto;
    if (parameters.isSolved) {
        if (isEpisode) {
            return <><span className={`material-symbols-rounded success`}>select_check_box</span></>;
        } else {
            return <><span className={`material-symbols-rounded success`}>task_alt</span></>;
        }    
    }
    
    if (isEpisode) {
        return <><span className={`material-symbols-rounded`}>check_box_outline_blank</span></>;
    } else {
        return <><span className={`material-symbols-rounded`}>circle</span></>;
    }
}