import './PuzzleView.css';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { format } from 'date-fns';

import { PuzzleApi } from '../api/PuzzleApi';

import Puzzle from '../models/Puzzle';
import EpisodeDto from '../models/Episode';

import EpisodeMenu from '../components/Puzzle/EpisodeMenu';
import DataLibrary from '../components/Puzzle/DataLibrary';
import PuzzleContent from '../components/Puzzle/PuzzleContent';

type PuzzleViewPathParameters = {
    id: string
}

class DisplayError {

    errorType: DisplayErrorType;
    message: string;
    dependsOn: number[] = [];
    validAfter: Date | null = null;

    constructor(errorType: DisplayErrorType, message: string) {
        this.errorType = errorType;
        this.message = message;
    }

}

enum DisplayErrorType {

    UNKNOWN,
    NOT_FOUND,
    DATE_RESTRICTED,
    MISSING_SOLVED_PUZZLES

}

export default function PuzzleView() {
    const { id } = useParams<PuzzleViewPathParameters>();

    const [puzzle, setPuzzle] = useState<Puzzle | null>(null);
    const [episode, setEpisode] = useState<EpisodeDto | null>(null);
    const [error, setError] = useState<DisplayError | null>(null);

    useEffect(() => {
        var puzzleId = id ? parseInt(id) : -1;
        if (puzzleId >= 0) {
            PuzzleApi.getPuzzleById({id: puzzleId, year: 2022})
                .then(puzzle => {
                    setError(null);
                    setPuzzle(puzzle);
                })
                .catch(error => {
                    if (error.response.status == 404) {
                        var displayError: DisplayError = new DisplayError(DisplayErrorType.NOT_FOUND, "");
                        setError(displayError);
                    } else if (error.response.status == 403) {
                        if (error.response.data) {
                            var displayError: DisplayError;
                            if (error.response.data.error === "access_denied_unknown_content") {
                                displayError = new DisplayError(DisplayErrorType.UNKNOWN, "");
                                setError(displayError);
                            } else if (error.response.data.error === "access_denied_date_invalid") {
                                displayError = new DisplayError(DisplayErrorType.DATE_RESTRICTED, "");
                                displayError.validAfter = new Date(Date.parse(error.response.data.valid_after));
                                setError(displayError);
                            } else if (error.response.data.error === "access_denied_dependencies_not_matched") {
                                displayError = new DisplayError(DisplayErrorType.MISSING_SOLVED_PUZZLES, "");
                                displayError.dependsOn = error.response.data.depends_on;
                                setError(displayError);
                            }
                        } else {
                            setError(new DisplayError(DisplayErrorType.UNKNOWN, ""));
                        }
                    }
                    return error.response;
                });
            PuzzleApi.getEpisodeByPuzzleId({id: puzzleId, year: 2022})
                .then(episode => {
                    setEpisode(episode);
                })
        }
    }, [id]);

    if (error) {
        if (error.errorType === DisplayErrorType.DATE_RESTRICTED) {
            if (error.validAfter) {
                var validAfter = format(error.validAfter, "dd.MM.yyyy HH:mm");
                return (<div className="puzzle-page">
                    <div id="content" className="hud-box">
                        <div className="content">
                            <h1>Access Denied</h1>
                            <p>Puzzle only accessible after {validAfter}</p>
                        </div>
                    </div>
                </div>);
            } else {
                return (<div className="puzzle-page">
                    <div id="content" className="hud-box">
                        <div className="content">
                            <h1>Access Denied</h1>
                            <p>Puzzle only accessible after unknown date.</p>
                        </div>
                    </div>
                </div>);
            }
        } else if (error.errorType === DisplayErrorType.MISSING_SOLVED_PUZZLES) {
            return (<div className="puzzle-page">
                <div id="content" className="hud-box">
                    <div className="content">
                        <h1>Access Denied</h1>
                        <p>You need to solve the following puzzles to be able to access this one: {error.dependsOn}</p>
                    </div>
                </div>
            </div>);
        } else if (error.errorType === DisplayErrorType.NOT_FOUND) {
            return (<div className="puzzle-page">
                <div id="content" className="hud-box">
                    <div className="content">
                        <h1>Puzzle not found.</h1>
                    </div>
                </div>
            </div>);
        }
        return (<div className="puzzle-page">
            <div id="content" className="hud-box">
                <div className="content">
                    <h1>Access Denied</h1>
                    <p>No further details provided.</p>
                </div>
            </div>
        </div>);
    } else if (puzzle && puzzle.content) {
        return (
            <div className="puzzle-page">
                <div id="content" className="hud-box">
                    <PuzzleContent episode={episode} puzzle={puzzle} />
                </div>
                <div id="sidebar" className="sidebar">
                    <EpisodeMenu episode={episode} />
                    <DataLibrary puzzle={puzzle} />
                </div>
            </div>
        );
    }
    return (<div></div>);
}