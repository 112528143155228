import { ApiUtils } from "./ApiUtils";

import EpisodeDto, { RestrictionDto, StageDto } from "../models/Episode";

class EpisodeApiImpl {

    public static INSTANCE: EpisodeApiImpl = new EpisodeApiImpl();

    public get(): Promise<EpisodeDto[]> {
        return ApiUtils.restApi().get('episode/')
            .then(jsonResult => {
                if (jsonResult.data) {
                    const resultList: EpisodeDto[] = [];
                    jsonResult.data.map((value: EpisodeDto, index: number) => {
                        var result = this.mapToDto(value);
                        resultList.push(result);
                    });
                    return resultList;
                }
                return [];
            });
    }

    public getById(id: number): Promise<EpisodeDto> {
        return ApiUtils.restApi().get(`episode/${id}`)
            .then(jsonResult => {
                return this.mapToDto(jsonResult.data);
            });
    }

    public mapToDto(value: EpisodeDto): EpisodeDto {
        var result = Object.assign(new EpisodeDto(), value);
        if (value.stages) {
            result.stages = value.stages.map(stage => Object.assign(new StageDto(), stage));
        }
        if (value.restrictions) {
            result.restrictions = Object.assign(new RestrictionDto(), value.restrictions);
        }
        return result;
    }
}

export const EpisodeApi = EpisodeApiImpl.INSTANCE;