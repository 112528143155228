import './index.css';
import './components/Site/Main.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import reportWebVitals from './reportWebVitals';

import WelcomeView from './views/WelcomeView';
import PuzzleView from './views/PuzzleView';
import BlogView from './views/BlogView';

import Header from './components/Site/Header';
import Footer from './components/Site/Footer';

import Login from './components/User/Login';
import CreditsView from './views/CreditsView';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Header />
      <main role="main">
        <Routes>
          <Route path="/" element={<WelcomeView />} />
          <Route path="puzzle/:id" element={<PuzzleView />} />
          <Route path="blog/:title" element={<BlogView />} />
          <Route path="/login" element={<Login />} />
          <Route path="credits" element={<CreditsView />} />
        </Routes>
      </main>
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
