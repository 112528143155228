import "./EpisodeMenu.css";

import { NavLink } from 'react-router-dom';

import EpisodeDto, { StageDto } from "../../models/Episode";
import PuzzleMenuEntry from "./PuzzleMenuEntry";

export default function EpisodeMenu(parameters: { episode: EpisodeDto | null }) {
    if (parameters.episode && parameters.episode.stages) {
        var episode: EpisodeDto = parameters.episode;
        return (
            <div className="hud-box">
                <nav role="navigation" className="episode-menu">
                    <hgroup className="header">
                        <h1>Navigation</h1>
                        <NavLink to={`/`} className="nav-button">
                            <span className="title">Headquarter</span>
                            <><span className={`material-symbols-rounded`}>home</span></>
                        </NavLink>
                        <h2>
                            {episode.episodeTitle}
                        </h2>
                    </hgroup>
                    <ul className="list">
                        <li key={`episode_${episode.id.id}_${episode.puzzleId.id}`}><PuzzleMenuEntry stage={episode} wasSolved={episode.isSolved} /></li>
                        {episode.stages
                            .sort((s1: StageDto, s2: StageDto) => s1.puzzleId.id - s2.puzzleId.id)
                            .map(
                                (stage) =>
                                    <li key={`episode_${episode.id.id}_${stage.puzzleId.id}`}>
                                        <PuzzleMenuEntry stage={stage} wasSolved={stage.isSolved} />
                                    </li>
                            )
                        }
                    </ul>
                </nav>
            </div>
        );
    } else {
        return (
            <div>
                Episode not found.
            </div>
        );
    }
}