import './PuzzleContent.css';

import { ApiUtils } from '../../api/ApiUtils';

import Puzzle from '../../models/Puzzle';
import EpisodeDto from '../../models/Episode';

import AccessControlPanel from './AccessControlPanel';

type PuzzleContentProperties = {
    puzzle: Puzzle | null;
    episode: EpisodeDto | null;
}

export default function PuzzleContent(parameters: PuzzleContentProperties) {
    var puzzle = parameters.puzzle;
    if (puzzle && puzzle.content) {
        return (
            <div className="content">
                <div className="text" dangerouslySetInnerHTML={{ __html: puzzle.content.text.replace("CC_API_URL", ApiUtils.url()) }} />
                <AccessControlPanel episode={parameters.episode} puzzle={puzzle} solved={puzzle.isSolved} />
            </div>
        );
    }
    return (
        <div className="content">
            Puzzle not found
        </div>);
}
