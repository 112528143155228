import { AxiosResponse } from "axios";
import { BlogEntry } from "../models/BlogEntry";

import { ApiUtils } from "./ApiUtils";

class BlogApiImpl {

    public static INSTANCE: BlogApiImpl = new BlogApiImpl();

    public get(): Promise<AxiosResponse<any, any>> {
        return ApiUtils.restApi().get('blog/');
    }

    public getByTitle(title: string): Promise<BlogEntry> {
        return ApiUtils.restApi().get(`blog/2022/${title}`)
            .then(jsonResult => {
                if (jsonResult.data) {
                    return jsonResult.data;
                }
                return undefined;
            });
    }

}

export const BlogApi = BlogApiImpl.INSTANCE;