import "./Snackbar.css";

type SnackbarProperties = {
    open: boolean,
    onClose: () => void,
    message: string | null
}

export default function Snackbar(parameters: SnackbarProperties) {
    if (!parameters.open) {
        return (null);
    }
    return (
        <div id="access-notification" className="snackbar">
            <div><span className="material-symbols-rounded">lock_open</span></div><div>{parameters.message}</div>
            <a onClick={() => parameters.onClose()}><span className="material-symbols-rounded">close</span></a>
        </div>
    );
}