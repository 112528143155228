import "./WelcomeText.css";

import { useEffect, useState } from "react";

import { SessionUtils } from "../../api/SessionUtils";
import { UserApi } from "../../api/UserApi";

export default function WelcomeText() {

    const [showWelcome, setShowWelcome] = useState<boolean>(false);

    useEffect(() => {
        const user = SessionUtils.getUser();
        if (user?.welcomeDone) {
            setShowWelcome(false);
        } else {
            setShowWelcome(true);
        }
    }, []);

    function hasDoneWelcome() {
        UserApi.setProperty("welcome", "true")
            .then(user => SessionUtils.setUser(user))
        setShowWelcome(false);
    }

    if (!showWelcome) {
        return (null);
    }
    return (
        <div id="welcome-text">
            <div className="hud-box">
                <div className="content">
                    <div className="text">
                        <h1>Welcome to the Festo Coding Challenge 2022!</h1>
                        <p>In this challenge, you will be a sci-fi detective hunting space pirates. This page is the headquarter. Here you can see your progress and navigate between the individual puzzles.</p>
                        <p>To complete the challenge, finish Episode 1, 2 and 3 and bring all three space pirates to court. Solve as many puzzles as possible to earn evidence points. Can you collect enough evidence points to bring the pirates to prison?</p>
                        <p>To save your progress, create an account. If you want to be part of the prize draw, we also need your email address.</p>
                        <p>To start your journey, click on the Start-button in the Intro.</p>
                        <div className="center" onClick={(event) => hasDoneWelcome()}><button>Let's go!</button></div>
                    </div>
                </div>
            </div>
        </div>
    )
}